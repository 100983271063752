import { useCallback, useMemo } from 'react';
import QueryString from 'qs';
import useToday from '../sci-ui-components/hooks/useToday';
import {
  getAllRanges,
  formatISOIfExists,
  DateRangeName,
  IsoDateRange,
  getNumberOfDaysInDateRange,
} from '../sci-ui-components/utils/date';
import useQueryString, { getStringParam } from './useQueryString';
import { trackEvent } from '@/features/analytics/trackEvent';

type SetDateRangeFn = (dateRange: IsoDateRange) => void;

export default function useDateRangeQueryState(
  defaultDateRange: DateRangeName = 'Last 30 days'
): [IsoDateRange, SetDateRangeFn] {
  const today = useToday();
  const { query, setQueryParams } = useQueryString();
  const dateRange = useMemo<IsoDateRange>(() => {
    const dateRangeFromQuery = getDateRangeFromQs(query);
    if (!dateRangeFromQuery) {
      const [from, to] = getAllRanges(today)[defaultDateRange];
      return [formatISOIfExists(from), formatISOIfExists(to)];
    }
    return dateRangeFromQuery;
  }, [query, today, defaultDateRange]);

  const setDateRange = useCallback<SetDateRangeFn>(
    (dateRange) => {
      setQueryParams(getQsFromDateRange(dateRange));
      trackEvent({
        eventName: 'DATE_RANGE_CHANGED',
        startDate: dateRange[0],
        endDate: dateRange[1],
        daysInRange: getNumberOfDaysInDateRange(dateRange),
      });
    },
    [setQueryParams]
  );

  return [dateRange, setDateRange];
}

const qsKeys = {
  isoDateFrom: 'from',
  isoDateTo: 'to',
};

export function getQsFromDateRange(dateRange: IsoDateRange | null) {
  if (!dateRange?.[0] || !dateRange?.[1]) {
    return {};
  }
  return {
    [qsKeys.isoDateFrom]: dateRange[0],
    [qsKeys.isoDateTo]: dateRange[1],
  };
}

export function getDateRangeFromQs(query: QueryString.ParsedQs): IsoDateRange | null {
  const isoDateFrom = getStringParam(query, qsKeys.isoDateFrom, null);
  const isoDateTo = getStringParam(query, qsKeys.isoDateTo, null);
  if (!isoDateFrom || !isoDateTo) {
    return null;
  }
  return [isoDateFrom, isoDateTo];
}

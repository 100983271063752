import { faPen, faPlus, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import FieldLabelBox, { FieldLabelBoxProps } from '../../forms/FieldLabelBox/FieldLabelBox';
import SimpleSelect, { Option } from '../../forms/SimpleSelect/SimpleSelect';
import AddNewAdornmentButton from '../../buttons/AddNewAdornmentButton/AddNewAdornmentButton';
import IconButton from '../../buttons/IconButton/IconButton';

import classes from './CategoryFilter.module.scss';

export interface CategoryFilterProps<TCategory, TId extends string | number = number> {
  className?: string;
  categories: TCategory[] | undefined;
  selectedCategoryId: TId | null;
  onAddNew?: () => void;
  onEdit?: (category: TCategory) => void;
  onDelete?: (category: TCategory) => void;
  onChange: (newCategoryId: TId | null) => void;
  id: string;
  error?: string;
  noValueLabel?: string;
  getLabel: (category: TCategory) => string;
  getId: (category: TCategory) => TId;
  label?: string;
  notEmpty?: boolean;
  notEditable?: boolean;
  inline?: boolean;
  filterOption?: (input: string, option: any) => boolean;
  variant?: FieldLabelBoxProps['variant'];
}

export default function CategoryFilter<TCategory, TId extends string | number = number>({
  className,
  categories = [],
  selectedCategoryId,
  onAddNew,
  onChange,
  onDelete,
  onEdit,
  id: filterId,
  error,
  noValueLabel = 'All',
  getId,
  getLabel,
  label = 'Category',
  notEmpty = false,
  notEditable = false,
  inline = false,
  filterOption,
  variant,
}: CategoryFilterProps<TCategory, TId>) {
  const options: Option<TId>[] =
    categories?.map((category) => ({
      value: getId(category),
      label: getLabel(category),
    })) ?? [];
  const id = inline ? filterId : `${filterId}-category`;
  const selectedCategory = selectedCategoryId ? categories?.find((cat) => getId(cat) === selectedCategoryId) : null;

  return (
    <FieldLabelBox
      label={inline ? undefined : label}
      fieldId={id}
      className={className}
      error={error}
      adornmentRight={onAddNew && !inline ? <AddNewAdornmentButton onClick={onAddNew} /> : null}
      variant={variant}
    >
      <div className={classes.content}>
        <SimpleSelect
          className={classes.select}
          options={options}
          value={selectedCategoryId}
          onChange={onChange}
          filterOption={filterOption ?? undefined}
          noValueLabel={noValueLabel}
          id={id}
          notEmpty={notEmpty}
        />
        {inline && !!onAddNew && (
          <IconButton
            className={classes.inlineButton}
            faIcon={faPlus}
            title="Add New"
            onClick={onAddNew}
            size="medium"
            color="grey"
          />
        )}
        {!!onEdit && !!selectedCategory && (
          <IconButton
            className={classes.inlineButton}
            faIcon={faPen}
            title="Edit"
            onClick={() => onEdit(selectedCategory)}
            size="medium"
            color="grey"
            disabled={notEditable}
          />
        )}
        {!!onDelete && !!selectedCategory && (
          <IconButton
            className={classes.inlineButton}
            faIcon={faTrashCan}
            title="Delete"
            onClick={() => onDelete(selectedCategory)}
            size="medium"
            color="grey"
            disabled={notEditable}
          />
        )}
      </div>
    </FieldLabelBox>
  );
}

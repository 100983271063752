import { ReactNode } from 'react';
import Button from '../../buttons/Button/Button';

export default function AddNewAdornmentButton({
  className,
  children = 'Add New',
  onClick,
  disabled,
}: {
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
  disabled?: boolean;
}) {
  return (
    <Button className={className} type="link" size="small" onClick={onClick} minWidth={0} disabled={disabled}>
      {children}
    </Button>
  );
}

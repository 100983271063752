import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { SavedSearchCategory } from '../../../sci-ui-components/types/search';
import {
  getLocalSavedSearchCategories,
  GetLocalSavedSearchCategoriesParams,
} from '../../../services/sciApi/search/savedSearches';
import { ApiError } from '../../../utils/api';
import useSavedSearches from './useSavedSearches';
import { CollectibleType } from 'sci-ui-components/types/collectibleType';

export const keyPrefix = 'saved-search-category';
type Key = [typeof keyPrefix, GetLocalSavedSearchCategoriesParams];

export default function useSavedSearchCategories(
  { collectibleType }: { collectibleType: CollectibleType },
  options: UseQueryOptions<SavedSearchCategory[], ApiError, SavedSearchCategory[], Key> = {}
) {
  const { data: savedSearches } = useSavedSearches({ collectibleType });
  const queryResults = useQuery(
    [keyPrefix, { collectibleType }],
    async () => getLocalSavedSearchCategories({ collectibleType }),
    {
      ...options,
      enabled: options?.enabled ?? true,
    }
  );
  const combinedCategories = useMemo(() => {
    const categoriesFromApi =
      savedSearches?.reduce<SavedSearchCategory[]>((acc, { category }) => {
        if (category) {
          acc.push(category);
        }
        return acc;
      }, []) ?? [];
    const combined = [...categoriesFromApi, ...(queryResults.data ?? [])];
    const unique = combined.reduce<{
      idsSet: Set<string>;
      array: SavedSearchCategory[];
    }>(
      (acc, category) => {
        if (!acc.idsSet.has(category.id)) {
          acc.array.push(category);
        }
        acc.idsSet.add(category.id);
        return acc;
      },
      { idsSet: new Set(), array: [] }
    );
    return unique.array;
  }, [savedSearches, queryResults.data]);

  return {
    ...queryResults,
    data: combinedCategories,
  };
}

import qs, { ParsedQs } from 'qs';
import { ApiSavedSearch } from '../../types';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { SavedSearch } from '../../../../sci-ui-components/types/search';
import { DEFAULT_SAVED_SEARCH_CATEGORY } from '../../../../features/search/saved-searches/useFeatureSavedSearchCategories';
import { getSavedSearchCategoryNameFromId } from './savedSearchCategoryFromApi';

function convertToStringArrayParam(query: ParsedQs, key: string, combiner: string, defaultValue: string): string {
  const values = Object.entries(query).reduce<ParsedQs[keyof ParsedQs][]>((acc, [k, v]) => {
    if (k.includes(key)) {
      if (Array.isArray(v)) {
        acc = v;
      } else if (!!v?.length) {
        String(v)
          .split(',')
          .forEach((item) => {
            acc.push(v);
          });
      }
    }
    return acc;
  }, []);
  return !!values.length ? `${combiner}=${values.join(`&${combiner}=`)}` : defaultValue;
}

export function featureSavedSearchFromApi(
  searches: ApiSavedSearch[],
  collectibleType: CollectibleType,
  searchPrefix: string = ''
) {
  return searches.map((search) => {
    const query = qs.parse(search.query, {
      ignoreQueryPrefix: true,
      comma: true,
    }) as {
      players?: string | string[];
      sets?: string | string[];
      variations?: string | string[];
      years?: string | string[];
      grades?: string | string[];
    };

    const searchParams = [
      searchPrefix,
      convertToStringArrayParam(query, 'player', 'players', ''),
      convertToStringArrayParam(query, 'sets', 'card_sets', ''),
      convertToStringArrayParam(query, 'variation', 'card_set_vars', ''),
      convertToStringArrayParam(query, 'grade', 'grade_ids', ''),
      convertToStringArrayParam(query, 'year', 'years', ''),
    ];
    const savedSearch: SavedSearch = {
      id: search.id,
      title: search.title,
      category: search.category
        ? { id: search.category, name: search.category }
        : {
            id: DEFAULT_SAVED_SEARCH_CATEGORY,
            name: getSavedSearchCategoryNameFromId(DEFAULT_SAVED_SEARCH_CATEGORY),
          },
      collectibleIds: [],
      href: searchParams.filter((item) => !!item).join('&'),
      order: search.order!,
      collectibleType: collectibleType,
      createdAt: search.createdAt,
      updatedAt: search.updatedAt,
      userId: search.userId,
    };
    return savedSearch;
  });
}

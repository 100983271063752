import { SavedSearchCategory } from 'sci-ui-components/types/search';

export default function savedSearchCategoryFromApi(id: string): SavedSearchCategory | null {
  if (id && /^Saved_Searches$/i.test(id)) {
    return null;
  }
  return {
    id,
    name: getSavedSearchCategoryNameFromId(id),
  };
}

export function makeSavedSearchCategoryId(name: string): string {
  return name.trim().replace(/\s+/g, '_');
}

export function getSavedSearchCategoryNameFromId(id: string): string {
  return id.replace(/_/g, ' ');
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { Select } from 'antd';
import clsx from 'clsx';

import classes from './SimpleSelect.module.scss';
import { useSelectPopupContainer } from './useSelectPopupContainer';

export interface Option<TValue extends string | number = string> {
  value: TValue;
  label: string;
  disabled?: boolean;
}

export interface SimpleSelectProps<TValue extends string | number = string> {
  className?: string;
  value: TValue | null;
  options: Readonly<Option<TValue>[]>;
  onChange: (newValue: TValue | null) => void;
  onSelect?: () => void;
  noValueLabel?: string;
  id?: string;
  notEmpty?: boolean;
  dropdownMatchSelectWidth?: boolean;
  showSearch?: boolean;
  filterOption?: (input: string, option: any) => boolean;
}

export default function SimpleSelect<TValue extends string | number = string>({
  className,
  value,
  options,
  onChange,
  onSelect,
  noValueLabel = 'Not Selected',
  id,
  notEmpty,
  dropdownMatchSelectWidth = false,
  filterOption,
}: SimpleSelectProps<TValue>) {
  const { getPopupContainer } = useSelectPopupContainer();
  return (
    <div className={clsx(classes.selectWrapper, className)}>
      <Select
        showSearch
        filterOption={filterOption}
        optionFilterProp="children"
        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
        onChange={onChange}
        onSelect={onSelect}
        value={value}
        className={classes.select}
        id={id}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        getPopupContainer={getPopupContainer}
      >
        {!notEmpty && <Select.Option value={null}>{noValueLabel}</Select.Option>}
        {options.map(({ label, value, disabled }) => (
          <Select.Option key={value} value={value} label={label} disabled={disabled}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

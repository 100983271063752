import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { ApiOpenSearchItemSealedWax, ApiOpenSearchItemSportsCard } from '../../types';
import { SportsCardSearch, SealedWaxSearch, SearchBase, SearchItem } from '../types';

export default function openSearchFromApi(
  apiItem: ApiOpenSearchItemSealedWax | ApiOpenSearchItemSportsCard,
  collectibleType: CollectibleType
): SearchItem {
  const last14StartAvg = apiItem.last14_start_avg;
  const last14EndAvg = apiItem.last14_end_avg;
  const last14ChangeAmount = apiItem.last14_change;
  const last14ChangePercentage =
    typeof apiItem.last14_change_percentage !== 'undefined'
      ? apiItem.last14_change_percentage
      : last14StartAvg === 0
      ? 0
      : (100 * last14ChangeAmount) / last14StartAvg;
  const base: SearchBase = {
    adminImageUrl: apiItem.admin_image_url,
    allEndAvg: apiItem.all_end_avg,
    id: Number(apiItem.id),
    imageUrl: apiItem.image_url ?? apiItem.admin_image_url,
    last14ChangeAmount,
    last14ChangePercentage,
    last14Count: apiItem.last14_count,
    last14EndAvg,
    last14StartAvg,
    last14Total: apiItem.last14_total,
    lastSaleDate: apiItem.last_sale_date,
  };
  if (collectibleType === 'sports-card') {
    const sportsCardApiItem = apiItem as ApiOpenSearchItemSportsCard;
    const result: SportsCardSearch = {
      ...base,
      cardNumber: sportsCardApiItem.card_number,
      gradeId: Number(sportsCardApiItem.grade_id),
      gradeName: sportsCardApiItem.grade_name,
      packOdds: sportsCardApiItem.pack_odds,
      playerAlias: sportsCardApiItem.player_alias,
      playerId: Number(sportsCardApiItem.player_id),
      playerName: sportsCardApiItem.player_name,
      populationCount: sportsCardApiItem.population_count,
      printRun: sportsCardApiItem.print_run,
      rc: sportsCardApiItem.rc,
      setId: Number(sportsCardApiItem.set_id),
      setName: sportsCardApiItem.set_name,
      setYear: sportsCardApiItem.set_year,
      specificQualifier: sportsCardApiItem.specific_qualifier,
      sportId: Number(sportsCardApiItem.sport_id),
      sportName: sportsCardApiItem.sport_name,
      variationId: Number(sportsCardApiItem.variation_id),
      variationName: sportsCardApiItem.variation_name,
    };
    return result;
  }
  if (collectibleType === 'sealed-wax-card') {
    const waxApiItem = apiItem as ApiOpenSearchItemSealedWax;
    const result: SealedWaxSearch = {
      ...base,
      sportName: waxApiItem.sport_name,
      sportId: Number(waxApiItem.sport_id),
      boxTypeName: waxApiItem.box_type_name,
      boxTypeId: Number(waxApiItem.box_type_id),
      setName: waxApiItem.set_name,
      setId: Number(waxApiItem.set_id),
      setYear: waxApiItem.set_year,
    };
    return result;
  }
  throw new Error(`Unexpected collectible type: ${collectibleType}`);
}

import { useEffect } from 'react';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';
import { SavedSearchCategory } from '../../../sci-ui-components/types/search';
import { deleteLocalSavedSearchCategory } from '../../../services/sciApi/search/savedSearches';
import { ApiSavedSearchType } from '../../../services/sciApi/types';
import { getSavedSearchCategoryNameFromId } from '../../../services/sciApi/search/transformers/savedSearchCategoryFromApi';
import useFeatureSavedSearches from './useFeatureSavedSearches';
import useFeatureLocalSavedSearchCategories from './useFeatureLocalSavedSearchCategories';
import { CollectibleType } from 'sci-ui-components/types/collectibleType';

export const DEFAULT_SAVED_SEARCH_CATEGORY = 'Saved_Searches';
export const DEFAULT_SAVED_SEARCH_CATEGORY_NAME = 'DEFAULT SAVED SEARCH';

export default function useFeatureSavedSearchCategories({
  collectibleType,
  savedSearchType,
  enabled = true,
}: {
  collectibleType: CollectibleType;
  savedSearchType: ApiSavedSearchType;
  enabled?: boolean;
}) {
  const { data, isLoading } = useFeatureSavedSearches({ collectibleType, savedSearchType }, { enabled });
  const storageCategories = useFeatureLocalSavedSearchCategories({ collectibleType, savedSearchType, enabled });

  const savedSearches = data?.sort((a, b) => a.order - b.order) ?? [];

  const apiCategories = Object.keys(groupBy(savedSearches, 'category.id')).map<SavedSearchCategory>((category) => {
    return {
      id: category,
      name:
        category === DEFAULT_SAVED_SEARCH_CATEGORY
          ? DEFAULT_SAVED_SEARCH_CATEGORY_NAME
          : getSavedSearchCategoryNameFromId(category),
    };
  }, []);

  const duplicates = apiCategories.filter((apiCategory) =>
    storageCategories.map(({ id }) => id).includes(apiCategory.id)
  );

  useEffect(() => {
    if (duplicates.length) {
      duplicates.forEach((duplicate) => {
        deleteLocalSavedSearchCategory({
          collectibleType,
          categoryId: duplicate.id,
          savedSearchType: savedSearchType,
        });
      });
    }
  }, [collectibleType, duplicates, savedSearchType]);

  // We return an empty array of categories until we have finished loading
  // so that the default category can be preselected by the consumers.
  const categories = isLoading ? [] : uniqBy([...storageCategories, ...apiCategories], 'id');

  return {
    data: { categories, savedSearches },
  };
}

import { useMutation, useQueryClient } from 'react-query';
import { waitForConfirmation } from '../../../sci-ui-components/ConfirmationDialog';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import {
  updateSavedSearches,
  createLocalSavedSearchCategory,
  deleteLocalSavedSearchCategory,
  renameLocalSavedSearchCategory,
  RenameLocalSavedSearchCategory,
  DeleteLocalSavedSearchCategoryParams,
  CreateLocalSavedSearchCategoryParams,
  deleteSavedSearch,
} from '../../../services/sciApi/search/savedSearches';
import { showError, showSuccess } from '../../../services/toaster';
import { ApiSavedSearchType } from '../../../services/sciApi/types';
import { keyPrefix as savedSearchCategoriesKeyPrefix } from './useSavedSearchCategories';
import useSavedSearches, { keyPrefix as savedSearchesKeyPrefix } from './useSavedSearches';
import useFeatureSavedSearches, { featureSavedSearchesKeyPrefix } from './useFeatureSavedSearches';
import { trackEvent } from '@/features/analytics/trackEvent';

const keyPrefix = 'saved-search-categories';

type DeleteParams = Omit<DeleteLocalSavedSearchCategoryParams, 'collectibleType'>;
type RenameParams = Omit<RenameLocalSavedSearchCategory, 'collectibleType'>;
type CreateParams = Omit<CreateLocalSavedSearchCategoryParams, 'collectibleType'>;

export default function useSavedSearchCategoryMutations({
  collectibleType,
  savedSearchType,
}: {
  collectibleType: CollectibleType;
  savedSearchType?: ApiSavedSearchType;
}) {
  const queryClient = useQueryClient();
  const { data: allSavedSearches } = useSavedSearches({ collectibleType });
  const { data: featureSavedSearches } = useFeatureSavedSearches({ collectibleType, savedSearchType });

  const savedSearches = savedSearchType ? featureSavedSearches : allSavedSearches;

  const refetchFeatureSearches = () => {
    if (savedSearchType) {
      queryClient.refetchQueries([featureSavedSearchesKeyPrefix, savedSearchType]);
    }
  };

  const create = useMutation({
    mutationFn: async (params: CreateParams) =>
      createLocalSavedSearchCategory({
        collectibleType,
        ...params,
      }),
    mutationKey: [keyPrefix, 'create'],
    onSuccess: () => {
      queryClient.refetchQueries([savedSearchCategoriesKeyPrefix]);
      refetchFeatureSearches();
      showSuccess({
        message: `Saved search category created`,
      });
      trackEvent({
        eventName: 'SEARCH_SAVED_CATEGORY_ADDED',
        collectibleType,
      });
    },
    onError: () => {
      showError({
        message: `Failed to create saved search category. Please try again.`,
      });
    },
  });

  const rename = useMutation({
    mutationFn: async ({ id, name, savedSearchType }: RenameParams) => {
      const newCategory = renameLocalSavedSearchCategory({
        collectibleType,
        id,
        name,
        savedSearchType,
      });

      const savedSearchesToMove = savedSearches?.filter(({ category }) => category?.id === id) ?? [];
      if (savedSearchesToMove.length) {
        await updateSavedSearches(
          savedSearchesToMove.map(({ id }) => ({
            id,
            categoryId: newCategory.id,
          }))
        );
      }
      return newCategory;
    },
    mutationKey: [keyPrefix, 'rename'],
    onSuccess: () => {
      queryClient.refetchQueries([savedSearchesKeyPrefix]);
      queryClient.refetchQueries([savedSearchCategoriesKeyPrefix]);
      refetchFeatureSearches();
      showSuccess({
        message: `Renamed saved search`,
      });
      trackEvent({
        eventName: 'SEARCH_SAVED_CATEGORY_RENAMED',
        collectibleType,
      });
    },
    onError: () => {
      showError({
        message: 'Failed to rename saved search. Please try again.',
      });
    },
  });

  const remove = useMutation({
    mutationFn: async ({ categoryId, savedSearchType }: DeleteParams) => {
      deleteLocalSavedSearchCategory({
        collectibleType,
        categoryId,
        savedSearchType: savedSearchType,
      });
      const savedSearchesToDelete = savedSearches?.filter(({ category }) => category?.id === categoryId) ?? [];
      if (savedSearchesToDelete.length) {
        await Promise.all(
          savedSearchesToDelete.map(({ id }) =>
            deleteSavedSearch({
              id,
            })
          )
        );
      }
    },
    mutationKey: [keyPrefix, 'remove'],
    onSuccess: () => {
      queryClient.refetchQueries([savedSearchesKeyPrefix]);
      queryClient.refetchQueries([savedSearchCategoriesKeyPrefix]);
      refetchFeatureSearches();
      showSuccess({
        message: `Deleted saved search`,
      });
      trackEvent({
        eventName: 'SEARCH_SAVED_CATEGORY_REMOVED',
        collectibleType,
      });
    },
    onError: () => {
      showError({
        message: 'Failed to remove from Favorites. Please try again.',
      });
    },
  });

  const removeWithConfirmation = async (params: DeleteParams) => {
    const confirmed = await waitForConfirmation({
      title: 'Delete search category',
      description: `Are you sure you want to delete this saved search category?\nBy confirming you will delete this saved search category AND all of the saved search items it contains.`,
    });
    if (confirmed) {
      await remove.mutateAsync(params);
    } else {
      trackEvent({
        eventName: 'SEARCH_SAVED_CATEGORY_DELETE_CANCELLED',
        collectibleType,
      });
    }
    return confirmed;
  };

  return {
    create,
    rename,
    remove,
    removeWithConfirmation,
  };
}

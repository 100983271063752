import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { SavedSearch } from '../../../sci-ui-components/types/search';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { getSavedSearches, GetSavedSearchesParams } from '../../../services/sciApi/search/savedSearches';
import { ApiError } from '../../../utils/api';
import useAuth from '../../auth/useAuth';

export const keyPrefix = 'saved-searches';
export type UseSavedSearchesParams = {
  categoryId?: string | null;
  collectibleType: CollectibleType;
};
export type Key = [typeof keyPrefix, GetSavedSearchesParams];
export type SavedSearchesData = SavedSearch[];

export default function useSavedSearches(
  { categoryId, collectibleType }: UseSavedSearchesParams,
  options: UseQueryOptions<SavedSearchesData, ApiError, SavedSearchesData, Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResults = useQuery(
    [keyPrefix, { collectibleType }],
    async ({ signal }) => getSavedSearches({ collectibleType }, signal),
    {
      ...options,
      enabled: !!isLoggedIn && (options?.enabled ?? true),
    }
  );
  const data = useMemo(() => {
    if (typeof categoryId === 'undefined') {
      return queryResults.data;
    }
    return queryResults.data
      ?.filter(({ category }) => (categoryId === null ? !category : category?.id === categoryId))
      .sort(({ order: o1 }, { order: o2 }) => o1 - o2); // ascending order
  }, [categoryId, queryResults.data]);

  return {
    ...queryResults,
    data,
  };
}

import { ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import getPageNumbersArray from './getPageNumbersArray';

import classes from './Pagination.module.scss';

export interface PaginationProps {
  className?: string;
  page?: number | undefined | null;
  onPageChange?: (page: number) => void;
  onPrevPage?: () => void;
  onNextPage?: () => void;
  totalPages?: number | null;
  totalItems?: number | null;
  limit: number;
  limitOptions?: number[];
  onLimitChange?: (limit: number) => void;
  noCurrentPageInfo?: boolean;
  noPageJumps?: boolean;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  justify?: Justify;
  noPageSelection?: boolean;
}

export type Justify = 'center' | 'right';

const FIRST_PAGE = 0;

export default function Pagination({
  className,
  onPageChange,
  page: pageParam,
  totalPages: totalPagesParams,
  totalItems,
  limit,
  limitOptions,
  onLimitChange,
  noCurrentPageInfo = false,
  noPageJumps = false,
  hasNextPage: hasNextPageParam,
  hasPrevPage: hasPrevPageParam,
  onPrevPage,
  onNextPage,
  justify,
  noPageSelection = false,
}: PaginationProps) {
  const page = pageParam ?? 0;
  const totalPages = totalPagesParams ?? Math.ceil((totalItems ?? 0) / limit);
  const pageNumbers = useMemo(
    () =>
      noPageJumps
        ? [{ pageNumber: page + 1, type: 'page' }]
        : getPageNumbersArray({
            page: page + 1,
            totalPages,
          }),
    [page, totalPages, noPageJumps]
  );
  const hasPrevPage = typeof hasPrevPageParam !== 'undefined' ? hasPrevPageParam : page > FIRST_PAGE;
  const hasNextPage =
    typeof hasNextPageParam !== 'undefined'
      ? hasNextPageParam
      : totalPages
      ? page + (1 - FIRST_PAGE) < totalPages
      : true;

  // Eliminating "Page 1 of 0" issue
  const hasPages = totalPages !== 0;
  const currentPage = hasPages ? page + (1 - FIRST_PAGE) : 0;

  return (
    <div className={clsx(classes.root, { [classes.justifyRight]: justify === 'right' }, className)}>
      {!noCurrentPageInfo && (
        <span className={classes.pageInfo}>
          Page {currentPage}
          {typeof totalPages === 'number' ? ` of ${totalPages}` : ''}
        </span>
      )}
      <div className={classes.pageSelection}>
        {!noPageSelection && (
          <>
            <PageButton
              key="prev"
              isCurrent={false}
              disabled={!hasPrevPage}
              onClick={() => {
                if (hasPrevPage) {
                  onPageChange?.(page - 1);
                  onPrevPage?.();
                }
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </PageButton>
            {pageNumbers?.map(({ pageNumber, type }) => {
              const isCurrent = page + (1 - FIRST_PAGE) === pageNumber;
              return (
                <PageButton
                  key={pageNumber}
                  onClick={() => onPageChange?.(pageNumber - (1 - FIRST_PAGE))}
                  isCurrent={isCurrent}
                >
                  {type === 'page' ? pageNumber : '...'}
                </PageButton>
              );
            }) ?? null}
            <PageButton
              key="next"
              isCurrent={false}
              disabled={!hasNextPage}
              onClick={() => {
                if (hasNextPage) {
                  onPageChange?.(page + 1);
                  onNextPage?.();
                }
              }}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </PageButton>
          </>
        )}
      </div>
      {!!limitOptions && !!onLimitChange && (
        <div className={classes.limitSelection}>
          <span className={classes.limitText}>Show</span>
          {limitOptions.map((limitOption) => {
            const isCurrent = limit === limitOption;
            return (
              <LimitButton key={limitOption} isCurrent={isCurrent} onClick={() => onLimitChange(limitOption)}>
                {limitOption}
              </LimitButton>
            );
          })}
        </div>
      )}
    </div>
  );
}

function PageButton({
  className,
  children,
  isCurrent,
  onClick,
  disabled,
}: {
  className?: string;
  children: ReactNode;
  isCurrent: boolean;
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <Button
      className={clsx(classes.button, classes.pageButton, className)}
      size="middle"
      color="primary"
      ghost={isCurrent}
      type={isCurrent ? 'primary' : 'link'}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

function LimitButton({
  className,
  children,
  isCurrent,
  onClick,
}: {
  className?: string;
  children: ReactNode;
  isCurrent: boolean;
  onClick: () => void;
}) {
  return (
    <Button
      className={clsx(classes.button, classes.limitButton, className)}
      size="middle"
      color="primary"
      type={isCurrent ? 'text' : 'link'}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

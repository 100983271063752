import createDialogSingleton, { DialogProviderProps } from '../../../../sci-ui-components/utils/createDialogSingleton';
import { EditSavedSearchCategoryDialogInfoProps, EditSavedSearchCategoryDialogResult } from './types';

const { invokeDialog: editOrAddSavedSearchCategory, registerDialog } = createDialogSingleton<
  EditSavedSearchCategoryDialogInfoProps,
  EditSavedSearchCategoryDialogResult
>();

export { editOrAddSavedSearchCategory, registerDialog };
export type EditSavedSearchCategoryDialogProviderProps = DialogProviderProps<
  EditSavedSearchCategoryDialogInfoProps,
  EditSavedSearchCategoryDialogResult
>;

import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { ApiSavedSearchType } from '../../../services/sciApi/types';
import { SavedSearchCategory } from '../../../sci-ui-components/types/search';
import { getStorageCategoryKey } from '../../../services/sciApi/search/savedSearches';
import useLocalStorageValue from '../../../hooks/useLocalStorageValue';
import { makeSavedSearchCategoryId } from '../../../services/sciApi/search/transformers/savedSearchCategoryFromApi';

type SelectedCategories = Partial<Record<CollectibleType, string[]>>;

const useFeatureLocalSavedSearchCategories = ({
  collectibleType,
  savedSearchType,
  enabled = true,
}: {
  collectibleType: CollectibleType;
  savedSearchType: ApiSavedSearchType;
  enabled?: boolean;
}) => {
  const [values] = useLocalStorageValue<SelectedCategories>(getStorageCategoryKey(savedSearchType), true);

  if (!values || !enabled) return [] as SavedSearchCategory[];

  return (
    values[collectibleType]?.map((category) => ({ id: makeSavedSearchCategoryId(category), name: category })) ?? []
  );
};

export default useFeatureLocalSavedSearchCategories;

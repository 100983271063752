import qs from 'qs';
import { SortDirection } from '../../../sci-ui-components/types/sort';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiOpenSearchItemSealedWax, ApiOpenSearchItemSportsCard } from '../types';
import { getOSTextSearchParams } from '../../../features/sales/helpers';
import { RawCompletedSaleSearchItemSpellCheckSuggestions } from '../../../features/sales-history/useRawCompletedSalesSearch';
import openSearchFromApi from './transformers/openSearchFromApi';
import { SearchItem } from './types';

export interface OpenSearchResponse {
  items: SearchItem[];
  next: number;
  total: number;
  spellCheckSuggestions?: RawCompletedSaleSearchItemSpellCheckSuggestions;
}

export interface OpenSearchParams {
  collectibleType: CollectibleType;
  query: string;
  offset: number;
  limit: number;
  cardSetYears?: number[] | null;
  collectibleIds?: number[] | null;
  playerIds?: number[] | null;
  sportIds?: number[] | null;
  cardSetIds?: number[] | null;
  cardVariationIds?: number[] | null;
  cardSetVariationIds?: number[] | null;
  cardNumber?: string | null;
  cardSpecificQualifier?: string | null;
  cardGradeIds?: number[] | null;
  isRookieOnly?: boolean | null;
  sealedWaxBoxTypeIds?: number[] | null;
  sortBy?: OpenSearchSortByValue | null;
  sortDirection?: SortDirection | null;
}

export type OpenSearchSortByValue = 'all_end_avg' | 'last14_change' | 'last14_change_percentage';

export default async function openSearch(
  {
    limit = 0,
    offset = 10,
    query = '',
    cardGradeIds,
    cardNumber,
    cardSetIds,
    cardSetVariationIds,
    cardVariationIds,
    cardSpecificQualifier,
    collectibleIds,
    playerIds,
    sportIds,
    cardSetYears,
    isRookieOnly,
    sealedWaxBoxTypeIds,
    collectibleType,
    sortDirection,
    sortBy,
  }: OpenSearchParams,
  signal?: AbortSignal
): Promise<OpenSearchResponse> {
  const universe = getApiUniverse(collectibleType);
  const { data, total, spellCheckSuggestions } = await authenticatedFetchFromSciApi<{
    data: ApiOpenSearchItemSportsCard[] | ApiOpenSearchItemSealedWax[];
    total: number;
    spellCheckSuggestions: RawCompletedSaleSearchItemSpellCheckSuggestions;
  }>(
    `/opensearch/search${qs.stringify(
      {
        universe,
        query: getOSTextSearchParams(query || ''),
        limit,
        offset,
        sortBy,
        orderBy: sortDirection,
        id: collectibleIds,
        grade_id: cardGradeIds,
        player_id: playerIds,
        set_id: cardSetIds,
        sport_id: sportIds,
        variation_id: cardVariationIds,
        set_variation_id: cardSetVariationIds,
        year: cardSetYears,
        card_number: cardNumber,
        specific_qualifier: cardSpecificQualifier,
        is_rookie: isRookieOnly,
        box_type_id: sealedWaxBoxTypeIds,
      },
      {
        skipNulls: true,
        addQueryPrefix: true,
        arrayFormat: 'repeat',
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );

  return {
    items: data.map((item) => openSearchFromApi(item, collectibleType)),
    next: offset + data.length,
    total,
    spellCheckSuggestions,
  };
}

function getApiUniverse(collectibleType: CollectibleType) {
  switch (collectibleType) {
    case 'sports-card':
      return 'cards';
    case 'sealed-wax-card':
      return 'waxes';
    default:
      return 'cards';
  }
}

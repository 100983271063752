import { ApiSavedSearch } from '../../types';
import { generateCollectiblesChartHref } from '../../../../features/search/utils/collectibleChart';
import savedSearchCategoryFromApi from './savedSearchCategoryFromApi';
import { CollectibleType } from 'sci-ui-components/types/collectibleType';
import { SavedSearch } from 'sci-ui-components/types/search';

export default function savedSearchFromApi(
  apiItem: ApiSavedSearch,
  {
    collectibleIds,
    collectibleType,
    defaultOrder,
  }: {
    collectibleType: CollectibleType;
    collectibleIds: number[];
    defaultOrder: number;
  }
): SavedSearch {
  return {
    id: Number(apiItem.id),
    category: apiItem.category ? savedSearchCategoryFromApi(apiItem.category) : null,
    collectibleIds,
    collectibleType,
    createdAt: apiItem.created_at,
    order: apiItem.order ?? defaultOrder,
    title: apiItem.title,
    updatedAt: apiItem.updated_at,
    userId: apiItem.user_id,
    href: generateCollectiblesChartHref({ collectibleIds, collectibleType }),
  };
}

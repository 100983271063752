import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { Select, SelectProps, RefSelectProps } from 'antd';
import clsx from 'clsx';

import { useSelectPopupContainer } from '../SimpleSelect/useSelectPopupContainer';
import { SimpleSelectProps } from '../SimpleSelect/SimpleSelect';

import classes from '../SimpleSelect/SimpleSelect.module.scss';

export type { Option } from '../SimpleSelect/SimpleSelect';

export interface SingleSelectProps<TValue extends string | number = string> extends SimpleSelectProps<TValue> {
  disabled?: boolean;
  allowClear?: boolean;
  showSearch?: boolean;
  isLoading?: boolean;
  fieldLabel?: string;
  fieldLabelPlacement?: 'inline' | 'above';
  onSearch?: (search: string) => void;
  onFocus?: () => void;
}

function SingleSelect<TValue extends string | number = string>(
  {
    className,
    value,
    options,
    onChange,
    noValueLabel = 'Not Selected',
    id,
    notEmpty,
    disabled,
    allowClear,
    showSearch,
    onSearch,
    onFocus,
    isLoading,
    fieldLabel,
    fieldLabelPlacement,
  }: SingleSelectProps<TValue>,
  ref: React.ForwardedRef<RefSelectProps>
) {
  const { getPopupContainer } = useSelectPopupContainer();
  const filterOption: SelectProps<TValue, { children: TValue }>['filterOption'] = (input, option) => {
    if (!option) return false;
    const optionValue = String(option.children).toLowerCase().replace(' ', '');
    const inputValue = input.toLowerCase().toLowerCase().replace(' ', '');

    return optionValue.indexOf(inputValue) >= 0;
  };

  return (
    <div
      className={clsx(
        classes.selectWrapper,
        { [classes.fieldLabelInline]: fieldLabelPlacement === 'inline' },
        className
      )}
    >
      {!!fieldLabel && <label>{fieldLabel}</label>}
      <Select
        ref={ref}
        onChange={onChange}
        allowClear={allowClear}
        showSearch={showSearch}
        onSearch={onSearch}
        filterOption={filterOption}
        value={value}
        onFocus={onFocus}
        className={classes.select}
        id={id}
        disabled={disabled}
        loading={isLoading}
        getPopupContainer={getPopupContainer}
        {...(isLoading ? {} : { suffixIcon: <FontAwesomeIcon icon={faSortDown} /> })}
      >
        {!notEmpty && <Select.Option value={null}>{noValueLabel}</Select.Option>}
        {options.map(({ label, value, disabled }) => (
          <Select.Option key={value} value={value} disabled={disabled}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default React.forwardRef(SingleSelect) as <TValue extends string | number = string>(
  props: SingleSelectProps<TValue> & { ref?: React.ForwardedRef<RefSelectProps> }
) => ReturnType<typeof SingleSelect>;

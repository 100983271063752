import { useEffect, useState } from 'react';
import { startOfDay, addDays, differenceInMilliseconds } from 'date-fns';

export default function useToday() {
  const [today, setToday] = useState(getCurrentDay());
  useEffect(() => {
    const startOfNextDay = startOfDay(addDays(new Date(), 1));
    if (startOfNextDay > today) {
      const timeout = setTimeout(() => {
        setToday(getCurrentDay());
      }, differenceInMilliseconds(startOfNextDay, new Date()));
      return () => clearTimeout(timeout);
    }
  }, [today]);
  return today;
}

export function getCurrentDay() {
  return startOfDay(new Date());
}

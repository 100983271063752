import { useMemo } from 'react';
import QueryString from 'qs';
import {
  getBooleanParam,
  getNumericParam,
  getStringArrayParam,
  getStringParam,
  hasSomeProperties,
  makeBooleanParam,
} from '../../../utils/queryString';
import { RatioListParams } from '../../../services/sciApi/ratios/types';
import useQueryString from '../../../hooks/useQueryString';
import { RatioFilterType } from '../types';
import useLocalStorage from '../../../hooks/useLocalStorageValue';

type SettableRatioFilters = Omit<Record<keyof RatioListParams, string>, 'step'>;

type RatioFiltersFromStorage = Partial<Record<RatioFilterType, RatioListParams>>;

const storageKey = 'sci_ratios_filters';

const filterTypes = ['grade', 'variation', 'player'] as const;

export const ratioTypeQueryKey = 'rft';

export const RatioFilterKeys: SettableRatioFilters = {
  gradeIds: 'grade_ids',
  years: 'years',
  setIds: 'card_sets',
  variationIds: 'card_set_vars',
  variationNames: 'variationNames',
  maxAvgSale: 'max_avg',
  minAvgSale: 'min_avg',
  playerIds: 'players',
  rookieOnly: 'rc',
  includeStats: 'stats',
  sortBy: 'sort_by',
  orderBy: 'order_by',
  limit: 'limit',
  offset: 'offset',
  timespan: 'timespan',
  savedQuery: 'savedQuery',
  showFilters: 'showFilters',
  gradedCardsOnly: 'gradedCardsOnly',
};

const defaultFilters: RatioListParams = {};

export const getRatiosFiltersFromQs = (query: QueryString.ParsedQs): RatioListParams => {
  const gradeIds = getStringArrayParam(query, RatioFilterKeys.gradeIds, defaultFilters.gradeIds);
  const playerIds = getStringArrayParam(query, RatioFilterKeys.playerIds, defaultFilters.playerIds);
  const years = getStringArrayParam(query, RatioFilterKeys.years, defaultFilters.years);
  const setIds = getStringArrayParam(query, RatioFilterKeys.setIds, defaultFilters.setIds);
  const variationIds = getStringArrayParam(query, RatioFilterKeys.variationIds, defaultFilters.variationIds);
  const variationNames = getStringArrayParam(query, RatioFilterKeys.variationNames, defaultFilters.variationNames);
  const maxAvgSale = getNumericParam(query, RatioFilterKeys.maxAvgSale, defaultFilters.maxAvgSale);
  const minAvgSale = getNumericParam(query, RatioFilterKeys.minAvgSale, defaultFilters.minAvgSale);
  const timespan = getStringParam(query, RatioFilterKeys.timespan, defaultFilters.timespan);
  const rookieOnly = getBooleanParam(query, RatioFilterKeys.rookieOnly, defaultFilters.rookieOnly);
  const limit = getNumericParam(query, RatioFilterKeys.limit, defaultFilters.limit);
  const offset = getNumericParam(query, RatioFilterKeys.offset, defaultFilters.offset);
  const savedQuery = getBooleanParam(query, RatioFilterKeys.savedQuery, defaultFilters.savedQuery);
  const showFilters = getBooleanParam(query, RatioFilterKeys.showFilters, true);
  const gradedCardsOnly = getBooleanParam(query, RatioFilterKeys.gradedCardsOnly, false);

  return {
    gradeIds,
    playerIds,
    setIds,
    years,
    variationIds,
    variationNames,
    maxAvgSale,
    minAvgSale,
    rookieOnly,
    timespan,
    limit,
    offset,
    savedQuery,
    showFilters,
    gradedCardsOnly,
  };
};

export const useRatioFilters = () => {
  const { query, setQueryParams } = useQueryString();
  const ratioFilters = useMemo(() => getRatiosFiltersFromQs(query), [query]);
  const hasRatioFilters = hasSomeProperties(ratioFilters);
  const [storageRatioFilters, setStorageRatioFilters] = useLocalStorage<RatioFiltersFromStorage>(storageKey, true);
  const setRatioFilters = (filters: RatioListParams) => {
    setQueryParams({
      [RatioFilterKeys.gradeIds]: filters.gradeIds,
      [RatioFilterKeys.years]: filters.years,
      [RatioFilterKeys.setIds]: filters.setIds,
      [RatioFilterKeys.variationIds]: filters.variationIds,
      [RatioFilterKeys.variationNames]: filters.variationNames,
      [RatioFilterKeys.maxAvgSale]: filters.maxAvgSale,
      [RatioFilterKeys.playerIds]: filters.playerIds,
      [RatioFilterKeys.minAvgSale]: filters.minAvgSale,
      [RatioFilterKeys.timespan]: filters.timespan,
      [RatioFilterKeys.limit]: filters.limit,
      [RatioFilterKeys.offset]: filters.offset,
      [RatioFilterKeys.rookieOnly]: makeBooleanParam(filters.rookieOnly),
      [RatioFilterKeys.savedQuery]: makeBooleanParam(filters.savedQuery),
      [RatioFilterKeys.showFilters]: makeBooleanParam(filters.showFilters),
      [RatioFilterKeys.gradedCardsOnly]: makeBooleanParam(filters.gradedCardsOnly),
    });
  };

  const setSomeRatioFilters = (updates: Partial<RatioListParams>) => {
    setRatioFilters({ ...ratioFilters, ...updates });
  };

  const filterKey = useMemo(() => {
    return getStringParam<RatioFilterType>(
      query,
      ratioTypeQueryKey,
      'grade',
      (value) => !!value && filterTypes.includes(value)
    );
  }, [query]);

  const updateStorageRatioFilter = (update: RatioFiltersFromStorage) => {
    setStorageRatioFilters({ ...storageRatioFilters, ...update });
  };

  return {
    setRatioFilters,
    setSomeRatioFilters,
    setStorageRatioFilters,
    updateStorageRatioFilter,
    ratioFilters,
    hasRatioFilters,
    filterKey,
    storageRatioFilters,
  };
};

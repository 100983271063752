import { useQuery, UseQueryOptions } from 'react-query';
import { SavedSearch } from '../../../sci-ui-components/types/search';
import { getFeatureSavedSearch } from '../../../services/sciApi/search/savedSearches';
import { ApiError } from '../../../utils/api';
import useAuth from '../../auth/useAuth';
import { ApiSavedSearchType } from '../../../services/sciApi/types';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { ratioTypeQueryKey } from '../../ratios/hooks/useRatioQueryString';

export const featureSavedSearchesKeyPrefix = 'feature-saved-searches';

export type Key = [typeof featureSavedSearchesKeyPrefix, ApiSavedSearchType | undefined];

export type SavedSearchesData = SavedSearch[];

const getSearchItemPrefix = (savedSearch: ApiSavedSearchType) => {
  let prefix = '';

  switch (savedSearch) {
    case 'VARIATION_RATIOS':
      prefix = 'variation';
      break;
    case 'PLAYER_RATIOS':
      prefix = 'player';
      break;
    default:
      prefix = 'grade';
  }

  return `ratios?${ratioTypeQueryKey}=${prefix}&savedQuery=1`;
};

export default function useFeatureSavedSearches(
  { collectibleType, savedSearchType }: { collectibleType: CollectibleType; savedSearchType?: ApiSavedSearchType },
  options: UseQueryOptions<SavedSearch[], ApiError, SavedSearch[], Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResults = useQuery(
    [featureSavedSearchesKeyPrefix, savedSearchType],
    async ({ signal }) =>
      savedSearchType
        ? getFeatureSavedSearch(
            { collectibleType, apiSearchType: savedSearchType, searchPrefix: getSearchItemPrefix(savedSearchType) },
            signal
          )
        : [],
    {
      ...options,
      retry: 1,
      enabled: !!isLoggedIn && (options?.enabled ?? true),
    }
  );

  return queryResults;
}

import qs from 'qs';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { IsoDateRange } from '../../../sci-ui-components/utils/date';
import { getQsFromDateRange } from '../../../hooks/useDateRangeQueryState';
import { collectibleTypeQueryKey } from '../../collectibles/constants';
import { Route } from '../../app/constants';

export const queryKey = 'sid';

export const generateCollectiblesChartHref = ({
  collectibleType,
  collectibleIds,
  isoDateRange,
}: {
  collectibleIds: number[];
  collectibleType: CollectibleType;
  isoDateRange?: IsoDateRange;
}) => {
  const dateRangeQS = getQsFromDateRange(isoDateRange ?? null);
  return `${Route.Charts}${qs.stringify(
    {
      ...dateRangeQS,
      [queryKey]: collectibleIds,
      [collectibleTypeQueryKey]: collectibleType,
    },
    {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
    }
  )}`;
};

export const defaultPagePadding = 1;
export const defaultEdgeGroupSize = 1;
export const defaultMinGapSize = 2;
const FIRST_PAGE = 1;
const GAP_ITEM = 1;
const CURRENT_PAGE_ITEM = 1;

export type PageItemType = 'page' | 'gap';
export interface PageItem {
  pageNumber: number;
  type: PageItemType;
}

/**
 * Generates an array of page numbers with gaps based on current page and total pages. Page numbers start with 1
 */
export default function getPageNumbersArray({
  page: pageParam = FIRST_PAGE,
  totalPages: totalPagesParam,
  edgeGroupSize = defaultEdgeGroupSize,
  pagePadding = defaultPagePadding,
  minGapSize = defaultMinGapSize,
}: {
  page: number | undefined;
  totalPages: number | undefined | null;
  edgeGroupSize?: number;
  pagePadding?: number;
  minGapSize?: number;
}): PageItem[] {
  const totalPages = typeof totalPagesParam === 'number' ? totalPagesParam : pageParam + 1;
  const page = Math.max(Math.min(pageParam, totalPages), FIRST_PAGE);

  const length = CURRENT_PAGE_ITEM + pagePadding * 2 + defaultEdgeGroupSize * 2 + GAP_ITEM * 2; // NOTE: resulting array length

  const canFitAll = pagePadding * 2 + edgeGroupSize * 2 + 2 * (minGapSize - 1) + CURRENT_PAGE_ITEM >= totalPages;
  const hasStartGap = page - pagePadding - edgeGroupSize > minGapSize;
  const hasEndGap = totalPages - edgeGroupSize - (page + pagePadding) > minGapSize - 1;
  if (canFitAll) {
    return getPagesArray(FIRST_PAGE, totalPages);
  }
  if (!hasStartGap) {
    return [
      ...getPagesArray(FIRST_PAGE, length - GAP_ITEM - pagePadding),
      {
        pageNumber: length - pagePadding,
        type: 'gap',
      },
      ...getPagesArray(totalPages - edgeGroupSize + GAP_ITEM, totalPages),
    ];
  }
  if (!hasEndGap) {
    return [
      ...getPagesArray(FIRST_PAGE, edgeGroupSize),
      {
        pageNumber: totalPages - (length - edgeGroupSize - FIRST_PAGE),
        type: 'gap',
      },
      ...getPagesArray(totalPages - (length - GAP_ITEM - edgeGroupSize - FIRST_PAGE), totalPages),
    ];
  }
  return [
    ...getPagesArray(FIRST_PAGE, edgeGroupSize),
    {
      pageNumber: page - pagePadding - GAP_ITEM,
      type: 'gap',
    },
    ...getPagesArray(page - pagePadding, page + pagePadding),
    {
      pageNumber: page + pagePadding + GAP_ITEM,
      type: 'gap',
    },
    ...getPagesArray(totalPages - edgeGroupSize + GAP_ITEM, totalPages),
  ];
}

function getPagesArray(start: number, end: number): PageItem[] {
  return Array.from({ length: end + 1 - start }).map((_, index) => ({
    pageNumber: start + index,
    type: 'page',
  }));
}
